
.contact-section-1 {
    background-color: #f8f9fa; 
    padding: 60px 0;
    margin-bottom:100px; 
}

.contact-wrapper-area {
    display: flex;
    flex-direction: column;
}

.contact-content {
    background: white; 
    border-radius: 8px; 
    padding: 30px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
}

.section-title {
    margin-bottom: 20px; 
}

.section-title h2 {
    font-size: 28px; 
    font-weight: 600; 
    color: #333; 
}

.contact-form-items .form-clt {
    margin-bottom: 15px; 
}

.contact-form-items input,
.contact-form-items textarea {
    width: 100%;
    padding: 10px; 
    border: 1px solid #ced4da;
    border-radius: 4px; 
    font-size: 16px; 
}

.contact-form-items button.theme-btn {
    background-color: #007bff; /* Primary button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Button padding */
    border-radius: 4px; /* Rounded button */
    cursor: pointer; /* Pointer on hover */
}

.contact-right-items {
    text-align: center; /* Center-align content */
    margin-top: 20px; /* Space above */
}

.contact-right-items .icon-items {
    margin-bottom: 20px; /* Space between icon items */
}

.icon img {
    width: 40px; /* Icon size */
    margin-bottom: 10px; /* Space below icons */
}

.social-icon a {
    margin: 0 10px; 
    font-size: 20px; 
    color: #007bff; 
}

.social-icon a:hover {
    color: #0056b3; 
}


@media (max-width: 768px) {
    .contact-section-1 {
        padding: 40px 0; 
    }

    .contact-content {
        padding: 20px; 
    }
}
