.services {
    position: relative;
    padding: 100px 0;
    background-color: #f8f9fa;
}

.bg-section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
}

.overlay.overlay-2 {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.sec-title {
    text-align: left;
    padding-bottom: 30px;
    position: relative;
    z-index: 2;
}

.sec-title h2 {
    font-size: 55px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.sec-title h3 {
    font-size: 30px;
    font-weight: 400;
    color: #777;
}

.sec-explain {
    color:lightslategrey;
    font-size:20px;
}


.services-carousel {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 30px; 
    overflow-x: auto; 
    scroll-behavior: smooth;
}

.services-carousel-item {
    min-width: 300px;
    background-color:#b6d6e6;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0; 
}

.services-carousel-item .services-item-img-box {
    position: relative;
    display: block;
    height: 120px;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.services-carousel-item .services-item-img-box span {
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.services-carousel-item .item-box {
    padding: 25px;
    text-align: center;
}

.services-carousel-item .content-box h4 {
    font-size: 25px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.services-carousel-item .content-box p {

    font-size: 20px;
    color:#302c2c;
    margin-bottom: 15px;
}

.services-carousel-item .content-box a.more {
    color: #d4851e;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}

.services-carousel-item .content-box a.more:hover {
    text-decoration: underline;
}


.services-carousel-item i {
    font-size: 48px;
    color: #007bff;
    margin-bottom: 15px;
}


@media (max-width: 768px) {
    .sec-title h2 {
        font-size: 28px;
    }

    .sec-title h3 {
        font-size: 18px;
    }

    .sec-explain {
        font-size: 14px;
    }

    .services-carousel-item .services-item-img-box {
        height: 200px;
    }

    .services-carousel-item .item-box {
        padding: 15px;
    }
}

            .section-title h2 {
    white-space: nowrap;
}

.section-title {
    width: 100%; 
}

@media (max-width: 768px) {
    .section-title h2 {
        font-size: 18px; 
    }
}