
.product-features-box {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.product-features-box:hover {
    transform: translateY(-5px);
}

.product-features-box h4 {
    font-size: 20px;
    margin-bottom: 16px;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.product-features-box ul {
    list-style-type: none;
    padding: 0;
}

.product-features-box li {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
}

.product-features-box li:last-child {
    border-bottom: none;
}

.product-features-box li strong {
    font-weight: bold;
    color: rgb(45, 43, 43);
}

.product-features-box p.additional-info {
    font-size: 15px;
    color: rgb(224, 150, 39);
    margin-top: 20px;
}

.product-features-box .btn {
    display: inline-block;
    margin-right: 10px;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
}

.product-features-box .btn-primary {
    background-color: #007bff;
    color: white;
}

.product-features-box .btn-primary:hover {
    background-color: #0056b3;
}

.product-features-box .btn-secondary {
    background-color: #6c757d;
    color: white;
}

.product-features-box .btn-secondary:hover {
    background-color: #5a6268;
}


            .footer-section {
                margin-bottom: 50px; 
            }
        
            .modal {
                margin-top: 20px; 
            }
            .modal-backdrop {
                    z-index: 1050; 
                }
            .modal-content {
                    z-index: 1055; 
            }
            .mb-3{
                color:#0a6679;
            }


            
.content-box {
    background-color: #ffffff; 
    border: 1px solid #e0e0e0; 
    padding: 30px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 8px; 
    transition: box-shadow 0.3s ease, transform 0.3s ease; 
}

.content-box:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
    transform: translateY(-5px); 
}


.key-features-heading {
    font-size: 28px; 
    color: #007bff; 
    font-weight: bold;
    padding: 15px 25px; 
    border-bottom : 3px solid #007bff; 
    margin-bottom: 30px; 
    text-align: center; 
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif; 
}


.about-quote ul {
    list-style: none; 
    padding-left: 0; 
}

.about-quote ul li {
    font-size: 18px; 
    color: #555; 
    margin-bottom: 15px; 
    position: relative;
    padding-left: 30px; 
}

.about-quote ul li::before {
    content: "\2022"; 
    position: absolute;
    left: 0;
    top: 0;
    font-size: 24px; 
    color: #007bff; 
}


@media (max-width: 768px) {
    .key-features-heading {
        font-size: 24px; 
    }
    
    .about-quote ul li {
        font-size: 16px; 
    }
}


    

