#header-sticky {
    background-color: #f8f9fa; /* Background color for header */
    padding: 15px;
}

.header-main {
    display: flex;
    justify-content: space-between; /* Distributes space between logo and menu */
    align-items: center; /* Vertically centers items */
}

.header-left {
    flex: 1;
    display: flex;
    justify-content: center; /* Center-aligns the logo */
}

.logo {
    margin: 0;
}

.header-logo img {
    max-height: 100px; /* Adjust logo size */
    display: block;
}

.mean__menu-wrapper {
    flex: 2;
}

.main-menu {
    display: flex;
    justify-content: center; /* Center-aligns the menu */
}

/* Adjust logo if you want to align it differently */
.header-left {
    flex: none; /* Ensures header-left doesn't take extra space */
}

/* Header-right styling to align items properly */
.header-right {
    display: flex;
    align-items: center;
}
.btn-1 {
    
    display: inline-block;
    padding: 10px 20px;
   background-color: #0f69c9; /* Primary blue color */
   color: white;
   text-transform: uppercase;
   font-weight: bold;
   border: none;
   border-radius: 5px;
   text-decoration: none;
   transition: background-color 0.3s ease, box-shadow 0.3s ease;
   }