body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.ag-canvas {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;

  position: relative;
  visibility: visible;

  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  opacity: 1;
  transition: opacity 0.5s;

  z-index: 10;
  margin-top: -90px;
}
.ag-canvas_svg {
  padding-bottom: 20px;
  display: block;
  width: 80%;
  height: auto;
  max-height: 100%;
  margin-left: 1rem;
}
.ag-canvas_svg path {
  fill: #383d46;
  fill-opacity: 1;
  stroke: #21252b;
  stroke-opacity: 1;
  stroke-width: 0.5;
  transition: 0.5s;
}


        path {
    pointer-events: auto;
    cursor: pointer;
    z-index: 10;
    position: relative;
}

@media screen and (max-width: 767px) {
  .ag-canvas {
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    height: auto;
    padding: 4rem 1rem 1rem;
    display: none;
  }
  .ag-canvas_svg {
    max-height: calc(100% - 6rem);
    margin-left: 0;
  }
}


.ag-canvas {
            width: 100%;
            max-width: 1000px;
            margin: auto;
            position: relative;
        }


        #tooltip {
    position: absolute;
    display: none;
    background-color: rgba(0, 0, 0, 0.9); /* Darker background for better contrast */
    color: white;
    padding: 12px 16px; /* Add more padding for a spacious feel */
    border-radius: 8px; /* Slightly larger border radius for smoother corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    z-index: 1000;
    pointer-events: none; /* Prevent mouse events from interfering */
    white-space: nowrap; /* Prevent text wrapping */
    font-size: 14px; /* Larger font size for readability */
    line-height: 1.5; /* Increase line height for better spacing */
    transition: opacity 0.2s ease; /* Smooth transition effect */
    opacity: 0; /* Start with opacity 0 for fade effect */
}

#tooltip.visible {
    display: block; /* Show tooltip when visible class is added */
    opacity: 1; /* Fade in effect */
}


        /* Links in the tooltip */
        #tooltip a {
            color: #ff6347;       /* Color for links inside tooltip */
            text-decoration: none;
        }

        #tooltip a:hover {
            text-decoration: underline;
        }